import React from "react";
import ToolsWidgetLayout from "../../../../components/toolsWidgetLayout";
import PreSentenceDetentionCalculator from "../../../../components/preSentenceDetention/preSentenceDetentionCalculator";
import SEO from "../../../../components/seo";

class ToolsWidgetsPreSentenceDetentionPage extends React.Component
{
  constructor(props)
  {
    super(props);
  }

  render()
  {
    return (
      <ToolsWidgetLayout>
        <SEO
          keywords = { [`LawChest`, `pre-sentence`, `sentence`, `calculator`] }
          title = "Pre-Sentence Detention Calculator"
        />
        <section
          id = "utilitySection"
        >
          <PreSentenceDetentionCalculator
            isWidget = { true }
          />
        </section>
      </ToolsWidgetLayout>
    );
  }
}

export default ToolsWidgetsPreSentenceDetentionPage;
