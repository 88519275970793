import React from "react";
import { Link } from "gatsby";

import blackChestIcon from "../images/chest-icon-black.svg";

function WidgetFooter() {
  return (
    <footer
      id = "footer"
    >
      <nav
        className = "max-w-4xl mx-auto p-4 text-sm"
      >
        <p
          className = "text-center"
        >
          <a
            className = "no-underline"
            href = "/"
            target = "_blank"
            rel = "noopener noreferrer"
          >
            <img
              alt = "Chest"
              className = "fill-current h-8 w-8 mx-auto"
              height = "54"
              viewBox = "0 0 54 54"
              width = "54"
              src = { blackChestIcon }
            />
            Powered by
            { ' ' }
            <span
              className = "font-bold"
            >
              LawChest
            </span>
          </a>
        </p>
      </nav>
    </footer>
  );
}

export default WidgetFooter;
