import PropTypes from "prop-types";
import React from "react";
import WidgetFooter from "./widgetFooter.js";

class ToolsWidgetLayout extends React.Component
{
  constructor(props)
  {
    super(props);
  }

  render()
  {
    return (
      <div
        className = "flex"
      >
        <div className="flex flex-col font-sans w-full min-h-screen text-gray-900">
          <main
            id = "toolsLayoutMain"
            className = "flex-grow md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full"
          >
            {this.props.children}
          </main>

          <WidgetFooter />
        </div>
      </div>
    );
  }
}

ToolsWidgetLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default ToolsWidgetLayout;
